<template>
    <div class="bg-white rounded p-24">
        <b-overlay
            :show="loading"
            rounded
            opacity="0.6"
            spinner-variant="primary"
        >
            <div>
                <app-heading-3>Subscriptions</app-heading-3>
                <div class="text-small">Set subscription settings</div>
            </div>
            <div class="mt-1">
                <div class="row mb-1">
                    <div class="col-12">
                        <b-table-simple responsive class="border text-nowrap">
                            <b-thead>
                                <b-tr>
                                    <b-th class="bg-white" >Features</b-th>
                                    <b-th class="bg-white">Basic</b-th>
                                    <b-th class="bg-white ">Plus</b-th>
                                    <b-th class="bg-white">Professional</b-th>
                                </b-tr>
                            </b-thead>  
                            <b-tbody>
                                    <b-tr v-for="(feature, index) in subscription_features" :key="index">
                                        <b-td class="text-nowrap">{{feature.label}}</b-td>
                                        <b-td class="text-center ">
                                            <b-form-checkbox v-if="feature.key=='quickbooksIntegration'" v-model="feature.basic"></b-form-checkbox>
                                            <app-text-input type="number" :disabled="feature.key=='price'" class="w-120 my-0" v-else v-model="feature.basic"/>
                                        </b-td>
                                        <b-td class="text-center ">
                                            <b-form-checkbox v-if="feature.key=='quickbooksIntegration'" v-model="feature.plus"></b-form-checkbox>
                                            <app-text-input type="number" :disabled="feature.key=='price'" class="w-120 my-0" v-else v-model="feature.plus"/>
                                        </b-td>
                                        <b-td class="text-center ">
                                            <b-form-checkbox v-if="feature.key=='quickbooksIntegration'" v-model="feature.pro"></b-form-checkbox>
                                            <app-text-input type="number" :disabled="feature.key=='price'" class="w-120 my-0" v-else v-model="feature.pro"/>
                                        </b-td>
                                    </b-tr>
                            </b-tbody>
                        </b-table-simple>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <b-button variant="primary" :disabled="updateLoading" @click="setSubscriptionPlans">Save Changes</b-button>
                    </div>
                </div>
            </div>
        </b-overlay>
    </div>
</template>
<script>
import AppHeading3 from '@/components/app-components/AppHeading3.vue'
import AppTextInput from '@/components/app-components/AppTextInput.vue'
import { BButton,BFormCheckbox, BTableSimple, BThead,BTbody, BTr, BTh, BTd} from 'bootstrap-vue'
import {get_subscription_plans, set_subscription_plans} from '@/apis/user-settings'
export default {
    components: {
        AppHeading3,
        BButton,
        BFormCheckbox,
        BTableSimple,
        BThead,
        BTbody, 
        BTr, 
        BTh, 
        BTd,
        AppTextInput,
    },
    data(){
        return{
            current_password:'',
            new_password:'',
            confirm_password:'',
            subscription_features:[
                {
                    label:'Number of Documents',
                    key:'documentsForSignature',
                    basic:'0',
                    plus:'0',
                    pro:'0'
                },
                {
                    label:'Managed Released Payments',
                    key:'managedReleasePayments',
                    basic:'0',
                    plus:'0',
                    pro:'0' 
                },
                {
                    label:'Number of Invoices',
                    key:'invoiceSendReceive',
                    basic:'0',
                    plus:'0',
                    pro:'0'
                },
                {
                    label:'Number of Collaborators',
                    key:'partnerCollaboration',
                    basic:0,
                    plus:0,
                    pro:0
                },
                {
                    label:'Quickbook Integration',
                    key:'quickbooksIntegration',
                    basic:false,
                    plus:false,
                    pro:false
                },
                {
                    label:'Price',
                    key:'price',
                    basic:0,
                    plus:0,
                    pro:0
                },
            ],
            updateLoading: false,
            loading:false
        }
    },
    mounted(){
        this.getSubscriptionPlans()
    },
    methods:{
        getSubscriptionPlans(){
            this.loading = true
            get_subscription_plans().then(resp=>{
                this.loading = false
                resp.map(plan=>{
                    for (let [k,v] of Object.entries(plan)){
                        if(plan.name == 'Free'){
                            this.subscription_features.map(feature=>{
                                if(feature.key == k){
                                    feature.basic = v
                                }
                            })
                        }else if(plan.name == 'Plus'){
                            this.subscription_features.map(feature=>{
                                if(feature.key == k){
                                    feature.plus = v
                                }
                            })
                        }else{
                            this.subscription_features.map(feature=>{
                                if(feature.key == k){
                                    feature.pro = v
                                }
                            }) 
                        }
                    }
                })
            }).catch((err) => {
                this.$bvToast.toast(err.message, {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
                this.loading = false
            })
        },
        setSubscriptionPlans(){
            let plans={
                Free:this.setPlanData('basic'),
                Plus:this.setPlanData('plus'),
                Professional:this.setPlanData('pro')
            }
            this.updateLoading=true
            set_subscription_plans(plans).then(resp=>{
                this.$bvToast.toast(resp.message, {
                    title: 'Success',
                    variant:'success',
                    solid: true,
                });
                this.updateLoading=false
            }).catch(err=>{
                this.$bvToast.toast(err.message, {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
                this.updateLoading=false
            })
        },
        setPlanData(key){
            let obj={}
            this.subscription_features.map(feature=>{
                if(feature.key !='price'){
                    obj[feature.key] = feature[key]
                }
            })
            return obj
        }
    }
}
</script>
<style scoped>
.p-24{
    padding: 24px;
}
.ml-8{
    margin-left: 8px;
}
.mb-12{
    margin-bottom: 12px;
}
.button-bottom{
    position: absolute;
    bottom: 0;
}
.confirm-btn:hover{
    box-shadow: -4px 9px 20px -7px rgba(0, 75, 255,0.35);
    -webkit-box-shadow: -4px 9px 20px -7px rgba(0, 75, 255,0.35);
    -moz-box-shadow: -4px 9px 20px -7px rgba(0, 75, 255,0.35);
}


.text-bold{
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
}
.text-simple{
    font-size: 15px;
    line-height: 22px;
    font-weight: 600;
}
.w-120{
    width:120px !important;
}
</style>