import { render, staticRenderFns } from "./Notifications.vue?vue&type=template&id=450334b6&scoped=true"
import script from "./Notifications.vue?vue&type=script&lang=js"
export * from "./Notifications.vue?vue&type=script&lang=js"
import style0 from "./Notifications.vue?vue&type=style&index=0&id=450334b6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "450334b6",
  null
  
)

export default component.exports