<template>
    <div>
        <b-tabs pills active-nav-item-class="setting-tabs bg-light-primary border-0 shadow-none tab-font" @input="onTabChange">
            <b-tab active>
                <template #title >
                    <feather-icon icon="UserIcon" size="18"></feather-icon>
                    <span class="tab-font">Account</span>
                </template>
                <account-settings/>            
            </b-tab>
            <b-tab>
                <template #title >
                    <feather-icon icon="LockIcon" size="18"></feather-icon>
                    <span class="tab-font">Security</span>
                </template>
                <security-settings/>  
            </b-tab>
            <b-tab>
                <template #title >
                    <feather-icon icon="BellIcon" size="18"></feather-icon>
                    <span class="tab-font">Notifications</span>
                </template>
                <notifications-settings/>
            </b-tab>
            <b-tab>
                <template #title >
                    <feather-icon icon="DollarSignIcon" size="18"></feather-icon>
                    <span class="tab-font">Subscriptions</span>
                </template>
                <subscription-settings/>
            </b-tab>
        </b-tabs>   
    </div>
</template>
<script>
import {BTabs, BTab} from 'bootstrap-vue'
import AccountSettings from '@/components/functional-components/admin/settings/Account'
import SecuritySettings from '@/components/functional-components/admin/settings/Security'
import NotificationsSettings from '@/components/functional-components/admin/settings/Notifications'
import SubscriptionSettings from '@/components/functional-components/admin/settings/Subscriptions.vue'
export default{
    components:{
        BTabs,
        BTab,
        AccountSettings,
        SecuritySettings,
        NotificationsSettings,
        SubscriptionSettings
    },
    data(){
        return{

        }
    }
}
</script>
<style >
.tab-font{
    font-size: 15px !important;
    line-height: 18px !important;
    font-weight: 500 !important;
}
.setting-tabs.nav{
    flex-wrap: nowrap !important;
    overflow-x:auto !important;
}
</style>