<template>
    <div class="bg-white rounded p-24">
        <div>
            <app-heading-3>Change Password</app-heading-3>
        </div>
        <div class="mt-1">
            
            <validation-observer ref="resetForm" >
                <div class="row">
                    <div class="col-12 col-md-6">
                        <b-alert variant="danger" :show="errorMessage.length" class="p-1">{{errorMessage}}</b-alert>
                        <validation-provider #default="{ errors }" name="Current Password" vid="current_password" rules="required">
                            <app-password-input label="Current Password" v-model="current_password"  placeholder="Current password" :errors="errors"></app-password-input>
                        </validation-provider>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <validation-provider #default="{ errors }" name="Password" vid="password" rules="required|validPassword">
                            <app-password-input label="New Password" v-model="new_password"  placeholder="New password" :errors="[...errors, ...passwordError]"></app-password-input>
                        </validation-provider>
                    </div>
                    <div class="col-12 col-md-6">
                        <validation-provider #default="{ errors }" name="Confirm password" vid="confirm_password" rules="required|validPassword">
                            <app-password-input label="Confirm New Password" v-model="confirm_password" @input="sameAs" placeholder="Confirm password" :errors="[...errors, ...passwordError]"></app-password-input>
                        </validation-provider>
                    </div>
                </div>
            </validation-observer>
            <div class="row">
                <div class="col-12">
                    <div class="text-simple">Password Requirements:</div>
                    <ul class="mb-2 mt-1">
                        <li>Minimum 8 characters long - the more, the better</li>
                        <li>At least one uppercase character</li>
                        <li>At least one number, symbol, or whitespace character</li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <b-button variant="primary" :disabled="loading" @click="updatePassword()">Save Changes</b-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AppHeading3 from '@/components/app-components/AppHeading3.vue'
import AppHeading2 from '@/components/app-components/AppHeading2.vue'
import AppHeading4 from '@/components/app-components/AppHeading4.vue'
import AppNormalText from '@/components/app-components/AppNormalText.vue'
import AppPasswordInput from '@/components/app-components/AppPasswordInput.vue'
import { ValidationProvider, ValidationObserver,extend } from 'vee-validate'
import { required } from '@validations'
import {update_password} from '@/apis/user-settings'
extend('validPassword', value=>{
      var regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/;
      if(regex.test(value)){
        return true
      }else{
        return 'Password should be 8 characters long and contain atleast one capital letter and special character'
      }
    })
import {BAlert,BBadge, BButton, BFormGroup, BFormCheckbox} from 'bootstrap-vue'
export default {
    components: {
        AppHeading3,
        BBadge,
        AppHeading2,
        AppHeading4,
        AppNormalText,
        BButton,
        BFormGroup,
        BFormCheckbox,
        AppPasswordInput,
        ValidationProvider,
        ValidationObserver,
        BAlert
    },
    data(){
        return{
            current_password:'',
            new_password:'',
            confirm_password:'',
            loading:false,
            errorMessage:'',
            required,
            passwordError:[],
            loading:false,
            
        }
    },
    methods:{
        sameAs(){
            if(this.new_password==this.confirm_password){
                this.passwordError = []
            }else{
                this.passwordError = ['Passwords do not match']
            }
        },
        updatePassword(){
            this.loading=true
                let data={
                    currentPassword:this.current_password,
                    newPassword:this.new_password
                }
            this.$refs.resetForm.validate().then(success=>{
                if(!success || this.passwordError.length){
                    this.loading=false
                    this.$bvToast.toast('Please fill all the required fields & enter valid data', {
                        title: 'Error',
                        variant: 'danger',
                        solid: true,
                    });
                }else{
                    update_password(data).then(resp=>{
                    this.loading=false
                    this.$bvToast.toast('Password updated successfully', {
                        title: 'Success',
                        variant:'success',
                        solid: true,
                        })
                    }).catch(err=>{
                        this.loading=false
                        this.$bvToast.toast(err.message, {
                            title: 'Error',
                            variant: 'danger',
                            solid: true,
                        })
                    })
                }
               
               
            })
        }
    }
}
</script>
<style scoped>
.p-24{
    padding: 24px;
}
.ml-8{
    margin-left: 8px;
}
.mb-12{
    margin-bottom: 12px;
}
.button-bottom{
    position: absolute;
    bottom: 0;
}
.confirm-btn:hover{
    box-shadow: -4px 9px 20px -7px rgba(0, 75, 255,0.35);
    -webkit-box-shadow: -4px 9px 20px -7px rgba(0, 75, 255,0.35);
    -moz-box-shadow: -4px 9px 20px -7px rgba(0, 75, 255,0.35);
}


.text-bold{
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
}
.text-simple{
    font-size: 15px;
    line-height: 22px;
    font-weight: 600;
}
</style>