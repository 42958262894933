import http from "@/utils/http";
export async function update_user(data){
    const req = (await http.put(`/user`,data ));
    return req.data;
}
export async function update_password(data){
    const req = (await http.post(`/auth/change-password`,data ));
    return req.data;
}
export async function update_notifications_settings(data){
    const req = (await http.put(`user/notification-settings`,data ));
    return req.data;
}
export async function delete_user(){
    const req = (await http.delete(`user/`));
    return req.data;
}

export async function get_subscription_plans() {
    const req = (await http.get(`/subscription`));
    return req.data;   
}


export async function set_subscription_plans(data){
    const req = (await http.put(`/subscription`,data));
    return req.data;      
}