<template>
    <div class="bg-white rounded p-24">
        <b-overlay
            :show="loading"
            rounded
            opacity="0.6"
            spinner-variant="primary"
        >
        <div>
            <app-heading-3>Notifications</app-heading-3>
            <div class="text-small">We need permission from your browser to show notifications. <b>Request Permission</b></div>
        </div>
        <div class="mt-1">
            <div class="row mb-1">
                <div class="col-12">
                    <b-table-simple class="border text-nowrap" responsive>
                        <b-thead>
                            <b-tr>
                                <b-th class="bg-white" >Type</b-th>
                                <b-th class="bg-white">Email</b-th>
                                <b-th class="bg-white">Webapp</b-th>
                            </b-tr>
                        </b-thead>  
                        <b-tbody>
                            <b-tr v-for="(notification, index) in notifications" :key="index">
                                <b-td>{{notification.type}}</b-td>
                                <b-td class="text-center"><b-form-checkbox  v-model="notification.email"></b-form-checkbox></b-td>
                                <b-td class="text-center"><b-form-checkbox  v-model="notification.browser"></b-form-checkbox></b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <b-button variant="primary" :disabel="updateLoading" @click="saveNotifications()">Save Changes</b-button>
                </div>
            </div>
        </div>
        </b-overlay>
    </div>
</template>
<script>
import AppHeading3 from '@/components/app-components/AppHeading3.vue'
import AppHeading2 from '@/components/app-components/AppHeading2.vue'
import AppHeading4 from '@/components/app-components/AppHeading4.vue'
import AppNormalText from '@/components/app-components/AppNormalText.vue'
import AppPasswordInput from '@/components/app-components/AppPasswordInput.vue'
import {BBadge, BButton, BFormGroup, BFormCheckbox, BTableSimple, BThead,BTbody, BTr, BTh, BTd, BOverlay} from 'bootstrap-vue'
import {update_notifications_settings} from '@/apis/user-settings'
import {user_details} from '@/apis/auth'
import { ValidationProvider, ValidationObserver,extend } from 'vee-validate'
export default {
    components: {
        AppHeading3,
        BBadge,
        AppHeading2,
        AppHeading4,
        AppNormalText,
        BButton,
        BFormGroup,
        BFormCheckbox,
        AppPasswordInput,
        BTableSimple,
        BThead,
        BTbody, 
        BTr, 
        BTh, 
        BTd,
        BOverlay,
        ValidationProvider,
         ValidationObserver
    },
    data(){
        return{
            notifications:[
                {
                    type:'Notifications for documents',
                    key:'documents',
                    email:false,
                    browser:false,
                },
                {
                    type:'Notifications for invoices',
                    key:'invoices',
                    email:false,
                    browser:false,
                },
                {
                    type:'Notifications for payments',
                    key:'payments',
                    email:false,
                    browser:false,
                },
                {
                    type:'Notifications for users',
                    key:'users',
                    email:false,
                    browser:false,
                }
            ],
            loading:false,
            updateLoading:false
        }
    },
    mounted(){
        this.getUserDetails()
    },
    methods:{
        getUserDetails(){
            this.loading=true
            user_details().then(resp=>{
                this.loading=false
                this.setPermissions(resp.data.notificationSettings)
                
            }).catch(err=>{
                this.loading=false
                this.$bvToast.toast(err.message, {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            })
        },
        setPermissions(permissions){
            for(let [k,v] of Object.entries(permissions)){
                this.notifications.map(n=>{
                    if(n.key==k){
                        n.email=v.email
                        n.browser=v.browser
                    }
                })
            }
        },
        saveNotifications(){
            let notifications ={}
            this.notifications.map(n=>{
                let obj={}
                obj[n.key]={
                        email:n.email,
                        browser:n.browser
                    }
                notifications={
                    ...notifications,
                    ...obj
                }
            })
            this.updateLoading=true
            update_notifications_settings({notificationSettings:notifications}).then(resp=>{
                this.$bvToast.toast(resp.message, {
                    title: 'Success',
                    variant:'success',
                    solid: true,
                });
                this.getUserDetails()
                this.updateLoading=false
            }).catch(err=>{
                this.$bvToast.toast(err.message, {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
                this.updateLoading=false
            })
        }
    }
}
</script>
<style scoped>
.p-24{
    padding: 24px;
}
.ml-8{
    margin-left: 8px;
}
.mb-12{
    margin-bottom: 12px;
}
.button-bottom{
    position: absolute;
    bottom: 0;
}
.confirm-btn:hover{
    box-shadow: -4px 9px 20px -7px rgba(0, 75, 255,0.35);
    -webkit-box-shadow: -4px 9px 20px -7px rgba(0, 75, 255,0.35);
    -moz-box-shadow: -4px 9px 20px -7px rgba(0, 75, 255,0.35);
}


.text-bold{
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
}
.text-simple{
    font-size: 15px;
    line-height: 22px;
    font-weight: 600;
}
</style>